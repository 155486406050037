// import { useUiStore } from '~/store/ui'

export async function useAPIPropertiesFetch(endpoint: string) {
  const config = useRuntimeConfig()
  //   const uiStore = useUiStore()

  try {
    const response = await fetch(`${config.public.API_URL_PROPERTY_CATALOGUE}${endpoint}`)
    if (!response.ok) {
      throw response
    }
    const data = await response.json()
    return data
  } catch (error) {
    console.error('Fetch error:', error.status)
    throw error
  }
}
